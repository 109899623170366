<template>
  <v-app id="inspire">
    <nav-drawer v-model="showNavdrawer" :items="items"></nav-drawer>
    <app-bar @toggleNavDrawer="showNavdrawer = !showNavdrawer"></app-bar>
    <v-main>
      <div id="core-view">
        <APIError></APIError>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </div>
      <app-footer></app-footer>
    </v-main>
  </v-app>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'AppFrame',

  data () {
    // icons: https://material.io/resources/icons/?search=account&style=baseline
    return {
      baseNavItems: [
        { icon: 'house', text: 'Home', route: '/home' },
      ],
      toolsNavItems: [],
      adminNavItems: [],
      cmsNavItems: [],
      techNavItems: [],
      showNavdrawer: null,
    }
  },

  computed: {
    items () {
      return [
        {
          section: '',
          navItems: this.baseNavItems,
        },
        {
          section: 'Tools',
          navItems: this.toolsNavItems,
        },
        {
          section: 'Admin',
          navItems: this.adminNavItems,
        },
        {
          section: 'CMS imports',
          navItems: this.cmsNavItems,
        },
        {
          section: 'Tech',
          navItems: this.techNavItems,
        },
      ]
    }
  },

  components: {
    AppBar: () => import('@/components/AppBar'),
    AppFooter: () => import('@/components/AppFooter'),
    APIError: () => import('@/components/APIError'),
    NavDrawer: () => import('@/components/NavDrawer'),
  },

  // set nav bar items and load store data on application load, depending on permissions
  async mounted () {

    // Nav bar items
    // ---------------------------------

    // SEA tools

    /* not used currently - awaiting feeds for RSA
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'settings', text: 'Ad customizer', route: '/ad-customizer' })
    }*/
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'import_contacts', text: 'Bing rules', route: '/bing-rules' })
    }
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'content_copy', text: 'Cloning tool', route: '/cloning-tool' })
    }
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'post_add', text: 'Content creator', route: '/content-creator' })
    }
    if (auth.hasPermission('BizDev') || auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'local_offer', text: 'Discounts', route: '/discounts' })
    }
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'playlist_add', text: 'Keyword collector', route: '/keyword-collector' })
    }
    /* not used currently - awaiting new KW labeling data from Analytics
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'multiple_stop', text: 'Keyword relocation', route: '/keyword-relocation' })
    }*/
    if (auth.hasPermission('BizDev') || auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.toolsNavItems.push({ icon: 'remove_circle_outline', text: 'Negative keywords', route: '/negative-keywords' })
    }

    // Admin

    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.adminNavItems.push({ icon: 'account_balance', text: 'Accounts', route: '/accounts' })
    }
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.adminNavItems.push({ icon: 'assignment', text: 'Assignments', route: '/assignments' })
    }
    if (auth.hasPermission('BizDev') || auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.adminNavItems.push({ icon: 'public', text: 'Geos', route: '/geos' })
    }
    /* has not proved useful enough to show
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.adminNavItems.push({ icon: 'list', text: 'Negative KW lists', route: '/negative-keyword-lists' })
    }*/
    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.adminNavItems.push({ icon: 'forward_to_inbox', text: 'Notifications', route: '/notifications' })
    }

    // CMS imports

    if (auth.hasPermission('BizDev') || auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      this.cmsNavItems.push({ icon: 'source', text: 'Providers', route: '/providers' })
    }
    if (auth.hasPermission('SEA Senior') || auth.hasPermission('Tech')) {
      this.cmsNavItems.push({ icon: 'construction', text: 'Verticals', route: '/verticals' })
    }

    // Tech

    if (auth.hasPermission('User Mgmt') || auth.hasPermission('Tech')) {
      this.techNavItems.push({ icon: 'person', text: 'Users', route: '/users' })
    }
    if (auth.hasPermission('Tech')) {
      this.techNavItems.push({ icon: 'login', text: 'Sessions', route: '/sessions' })
    }

    // Store data
    // ---------------------------------

    // BizDev + SEA Basic

    if (auth.hasPermission('BizDev') || auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      try {
        await this.$store.dispatch('common/loadCountries')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'common/loadCountries', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('common/loadLanguages')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'common/loadLanguages', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('common/loadRegions')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'common/loadRegions', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadActiveProviders')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadActiveProviders', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadAdNetworks')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadAdNetworks', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadDiscountApprovalStati')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadDiscountApprovalStati', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadMatchTypes')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadMatchTypes', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadVerticals')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadVerticals', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('nkw/loadApprovalStati')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'cnkw/loadApprovalStati', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('system/loadActiveUsers')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'system/loadActiveUsers', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
    }

    // SEA Basic

    if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
      try {
        // await = block execution until promise is returned
        await this.$store.dispatch('bing/loadAccounts')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'bing/loadAccounts', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadDomainTypes')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadDomainTypes', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadKeywordRelocationStati')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadKeywordRelocationStati', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('core/loadSeaTeams')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'core/loadSeaTeams', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('google/loadAccounts')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'google/loadAccounts', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
      try {
        await this.$store.dispatch('google/loadCountryGeoTargets')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'google/loadCountryGeoTargets', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
    }

    // SEA Senior

    // if (auth.hasPermission('SEA Senior') || auth.hasPermission('Tech')) {}

    // User Mgmt

    if (auth.hasPermission('User Mgmt') || auth.hasPermission('Tech')) {
      try {
        await this.$store.dispatch('system/loadPermissions')
      } catch (errResp) {
        this.$store.commit('system/setAPIError', { method: 'GET', url: 'system/loadPermissions', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      }
    }

  },

}
</script>

<style>
.breadcrumb:hover {
  color: #1976d2;
}

.clickable:hover {
  cursor: pointer;
}

.comment-button {
  margin-left: -5px;
  min-width: 0 !important;
}

#core-view {
  padding-bottom: 100px;
}

.dt-header-sandybrown header {
  background-color: sandybrown !important;
}

.dt-header-lightblue header {
  background-color: lightblue !important;
}

.form-group-label {
  text-transform: uppercase;
}

.form-icon {
  background-color: #4caf50;
  border-radius: 4px;
  color: #fff !important;
  display: inline-flex;
  font-size: 24px !important;
  margin-right: 15px;
  margin-bottom: 4px;
  padding: 18px!important;
  vertical-align: middle;
}

.inner-content {
  position: relative;
  z-index: 2;
}

#inspire {
  background: #eee;
}

table thead.v-data-table-header th {
  font-size: 14px;
  font-weight: normal;
}

.table-icon {
  background-color: #4caf50;
  border-radius: 4px;
  color: #fff !important;
  display: inline-flex;
  font-size: 28px !important;
  margin-right: 15px;
  margin-bottom: 8px;
  padding: 24px!important;
  vertical-align: middle;
}

tr.declined {
  color: red;
}
tr.under-review {
  background-color: lightyellow;
}

.v-application a {
  color: inherit;
  text-decoration: none;
}
</style>
